<template>
  <div class="main-content auth">
    <div class="auth-container box">
      <Switch :switchValue="switchValue" @switchValueChanged="switchValue = !switchValue"/>
      <img class="auth__logo" src="@/assets/images/logos/LogoMeaning.svg">
      <div class="auth__titles">
        
        <h2>{{$t('login.iniciaSesion')}}</h2>
        <!--<p class="subtitle">{{$t('login.ingresadatos')}}</p>-->
        <p></p>
      </div> 
      <div class="auth__form">
        <!-- <div class="auth__social">
          <div class="auth__social-button auth__social-button--facebook">
            <img src="@/assets/images/logos/facebook.svg">
          </div>
          <div class="auth__social-button auth__social-button--google">
            <img src="@/assets/images/logos/google.svg">
          </div>
        </div> -->
        
    <div class="auth__own">
          <form @submit.prevent="signin()">
            <div class="form__group">
              <label class="form__label" for="email"> {{$t('login.correo')}}</label>
              <input class="form__input" id="email" type="email" v-model.trim="email" required autofocus>
               <span class="form__input-info"></span>
            </div>
            <div class="form__group">
              <label class="form__label" for="password">{{$t('login.contra')}}</label>
              <input class="form__input" id="password" type="password" v-model="password" required>
               <span class="form__input-info"></span>
            </div>
            
            
            <div class="form__actions">
              <button class="btn btn--primary btn--block" type="submit"> {{$t('login.iniciarSesion')}}</button>
              <router-link class="btn btn--primary btn--link btn--block" :to="{ name: 'Signup' }"> {{$t('login.registrate')}}</router-link>
              <router-link class="btn btn--link" :to="{ name: 'RequestPasswordRecovery' }"> {{$t('login.recuperarcontra')}}</router-link>
            </div>
          </form>
        </div>
        <br><br>
        <!-- <div>
            <GoogleLogin />
        </div> -->
      </div>
      <br><br>
    </div>
  </div>
</template>

<script>
/* global fbq */
import { inject } from 'vue'
import Switch from '../components/Switch.vue'
// import GoogleLogin from '../components/GoogleLogin.vue'


export default {
  components: { Switch }, //, GoogleLogin
  name: 'Login',
  setup () {
    const axios = inject('$axios')
    

    return { axios }
  },
  data () {
    return {
      email: '',
      password: '',
      switchValue: true,
    }
    
  },
  methods: {
    async signin () {
      const res = await this.axios.post('auth/signin', {
        email: this.email,
        password: this.password
      })
      if (res.data.token) {
        let authToken = `bearer ${res.data.token}`
        this.axios.defaults.headers.common['Authorization'] = authToken
        this.axios.get('init').then(() => {
          this.$cookies.set('jwt', authToken)
          this.$router.push('/signup')
        })
      } else {
        alert(this.$t('login.alertmsg'))
      }
    }
  },
  mounted () {
    // TODO: improve user logout
    this.$cookies.remove('jwt')
    
    // Meta pixel code
    function loadFacebookPixel() {
    !function (f, b, e, v, n, t, s) {
      if (f.fbq) return;
      n = f.fbq = function () {
        n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments);
      };
      if (!f._fbq) f._fbq = n;
      n.push = n;
      n.loaded = !0;
      n.version = '2.0';
      n.queue = [];
      t = b.createElement(e);
      t.async = !0;
      t.src = v;
      s = b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t, s);
    }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
  }
  window.addEventListener('load', function() {
  // Carga la biblioteca de Facebook Pixel
  loadFacebookPixel();

  // Inicializa el píxel de Facebook y realiza un seguimiento de la vista de página
  fbq('init', '1023050025682178');
  fbq('track', 'PageView');
});
}
}
</script>
